import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import ServiceCards from "./ServiceCards";
import "../index.css";

function Home() {
    const theme = useTheme();
    const backgroundStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/new-cover.webp)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "90vh", // Set a fixed height for the top section
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        flexDirection: "column", // Make the children stack vertically
        textAlign: "center", // Center-align the text
    };

    const whatDoWeDoSectionStyle = {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: '3rem 12rem',
    };

    return (
        <div>
            {/* Top section with background image */}
            <div style={backgroundStyle}>
                <div>
                    <img
                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                        alt="BMS"
                        style={{ marginBottom: '4rem', paddingTop: '6rem' }}
                        className="logo-image"
                    />
                </div>
                <Typography variant='body4'>Delivering reliable maritime services on a global platform to the satisfaction of our business partners</Typography>
            </div>


            {/* Bottom section with a different background color */}
            <div style={whatDoWeDoSectionStyle} className="smaller-padding">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h2" style={{ marginBottom: '1rem' }}>What Do We Do?</Typography>
                    <Typography variant='body1'>
                        BMS is a well-established integrated company globally serving the marine, offshore, oil & gas industries. With over 20 years of experience, our team consists of master mariners, marine engineers, and leaders from Marine Information Technology and Maritime Training.  Our Inspectors are located and spread out across states not only in Australia and New Zealand but also other countries like Singapore, China, India, Philippines, Dubai, Indonesia, Gulf area, various parts of Europe, USA, Canada etc.,
                    </Typography>
                </Grid>
            </div>

            <div>
                <ServiceCards />
            </div>

            {/* <div>
                <Grid container spacing={2} sx={{ marginTop: '3rem', marginBottom: '1rem', marginRight: '1rem', marginLeft: '1rem', paddingLeft: '3rem' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2" color='secondary' style={{ marginBottom: '1rem'}}>About us and our Team</Typography>
                        <Typography variant='body1'>
                            <ul>
                                <li>Service to 35 countries</li>
                                <li>Over 20 years of professional experience</li>
                                <li>24 hours of availability</li>
                                <li>Our workforce consists of</li>
                                <ul>
                                    <li>Marine engineers</li>
                                    <li>Master mariners</li>
                                    <li>Naval architects</li>
                                    <li>Project managers</li>
                                    <li>Technical Managers</li>
                                    <li>Dry dock superintendents</li>
                                    <li>TMSA/Dry BMS Auditors</li>
                                </ul>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2" style={{ marginBottom: '1rem' }}>Column 2</Typography>
                        <Typography variant='body1'>
                            Content for column 2 goes here.
                        </Typography>
                    </Grid>
                </Grid>
            </div> */}
        </div>
    );
};

export default Home;
