import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';



function ServiceDetail() {
    const theme = useTheme();
    const headerStyle = {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '3rem 12rem',
    };

    const serviceInfo = {
        'agency-services': {
            serviceName: 'Agency Services',
            serviceContent: "",
            imgPath: `${process.env.PUBLIC_URL}/images/5.jpg`,
            subServices: [
                'Husbandry',
                'Protective',
                'Cargo',
                'Crew and Logistic Support',
            ]
        },
        'inspections': {
            serviceName: 'Inspections',
            serviceContent: '',
            imgPath: `${process.env.PUBLIC_URL}/images/6.jpg`,
            subServices: [
                "Pre-Right Ship Inspections",
                "Pre-SIRE Inspections",
                "Pre-CDI Inspections",
                "Tech Supdt Inspection",
                "Marine Supdt Inspection",
                "Condition Inspection",
                "Pre-sale/purchase Inspection",
                "Pre-AMSA/USCG/PSC Inspection"
            ]
        },
        'audits': {
            serviceName: 'Audits',
            serviceContent: "",
            imgPath: `${process.env.PUBLIC_URL}/images/1.jpg`,
            subServices: [
                "ISM/ISPS/MLC - Internal",
                "Navigational Audit - Port and internal audit",
                "Cargo Audit - Port and real-time"
            ]
        },
        'surveys': {
            serviceName: 'Surveys',
            serviceContent: '',
            imgPath: `${process.env.PUBLIC_URL}/images/7.jpg`,
            subServices: [
                "Bunker",
                "Draft",
                "Machinery",
                "Damage",
                "Occasional Survey"
            ]
        },
        'marine-consultancy': {
            serviceName: 'Marine Consultancy',
            serviceContent: "",
            imgPath: `${process.env.PUBLIC_URL}/images/consultancy.jpg`,
            subServices: [
                "Root Cause Corrective Action",
                "GAP analysis",
                "SWOT analysis",
                "QMS restructuring",
                "Onboard Training-Port/Sailing",
                "Marine IT Services"
            ]
        },
        'operational-coordination': {
            serviceName: 'Operational Coordination & Support',
            serviceContent: '',
            imgPath: `${process.env.PUBLIC_URL}/images/2.webp`,
            subServices: [
                "PSC- Inspection support.",
                "AMSA/USCG?WATER POL- support.",
                "RIGHT ship inspection support.",
                "Hold Cleaning monitoring",
                "New installations monitoring.",
                "Dry-dock supervision.",
                "Shore workshop support.",
                "SIRE inspection support.",
                "CDI Inspection support."
            ]
        },
        'incident-and-injury-investigations': {
            serviceName: 'Incident & Injury Investigations',
            serviceContent: '',
            type: 'incident-and-injury-investigations',
            imgPath: `${process.env.PUBLIC_URL}/images/investigation.jpg`,
            subServices: [
                "Personnel",
                "Structural",
                "Environmental",
                "Machinery",
                "Cargo",
                "Asset reputation"
            ]
        },
        'warehouse-facilities': {
            serviceName: 'Warehouse Facilities + Spare Supplies',
            serviceContent: '',
            type: 'warehouse-facilities',
            imgPath: `${process.env.PUBLIC_URL}/images/facilities.JPG`,
            subServices: [
                "Details coming soon"
            ]
        }
    }
    const { type } = useParams();

    const {
        serviceName,
        imgPath,
        subServices = [],
    } = serviceInfo[type] || {
        serviceName: 'Service Details Coming Soon',
        serviceContent:
            "Service details coming soon! Inquire at info@bms-ship.com for more information.",
    };


    return (
        <>
            <div style={headerStyle}></div>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <img
                            src={imgPath}
                            alt={serviceName}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                borderRadius: '0.5rem',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            textAlign={'center'}
                        >
                            {serviceName}
                        </Typography>
                        <List>
                            {subServices.map((subService, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: 'list-item',
                                        listStyleType: 'disc',
                                        marginLeft: '2.5rem'
                                    }}
                                >
                                    <ListItemText primary={subService} />
                                </ListItem>
                            ))}
                        </List>

                        <Typography variant="body1">
                            {/* {serviceContent} */}
                        </Typography>

                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                            <Link to="/Contact">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    sx={{
                                        fontFamily:
                                            theme.typography.h2.fontFamily,
                                    }}
                                >
                                    Inquire Now
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ServiceDetail;