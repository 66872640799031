import React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Import the email icon
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer() {
    const theme = useTheme();

    return (
        <div style={{ backgroundColor: theme.palette.primary.main, color: '#fff', padding: '2rem 0', marginTop: '10rem' }}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h4">About Us</Typography>
                        <div style={{ marginTop: '0.5rem' }}>
                            <Typography variant="body3">
                                The key drivers of BMS are reliability, transparency, cost management, strategic diversification, and continuous innovation.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h4">Email Us</Typography>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <MailOutlineIcon style={{ marginRight: '0.5rem' }} /> {/* Email icon */}
                            <Typography variant="body3">info@bms-ship.com</Typography>
                        </div>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <MailOutlineIcon style={{ marginRight: '0.5rem' }} /> {/* Email icon */}
                            <Typography variant="body3">sb-@bms-ship.com</Typography>
                        </div>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <MailOutlineIcon style={{ marginRight: '0.5rem' }} /> {/* Email icon */}
                            <Typography variant="body3">nr@bms-ship.com</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography style={{ marginBottom: '1rem' }} variant="h4">Call Us</Typography>
                        <Typography variant="body1">India</Typography>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <WhatsAppIcon style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} /> {/* Phone icon */}
                            <Typography variant="body3">Phone: +91 7259474263</Typography>
                        </div>
                        <Typography variant="body1">Singapore</Typography>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <WhatsAppIcon style={{ marginRight: '0.5rem' }} /> {/* Phone icon */}
                            <Typography variant="body3">Phone: +65 94602507</Typography>
                        </div>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <WhatsAppIcon style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} /> {/* Phone icon */}
                            <Typography variant="body3">Phone: +65 85240996</Typography>
                        </div>
                        <Typography variant="body1">Australia</Typography>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <WhatsAppIcon style={{ marginRight: '0.5rem' }} /> {/* Phone icon */}
                            <Typography variant="body3">Phone: +61 405259415</Typography>
                        </div>
                        <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <WhatsAppIcon style={{ marginRight: '0.5rem' }} /> {/* Phone icon */}
                            <Typography variant="body3">Phone:  + 61 456383917</Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="h4">Quick Links</Typography>
                        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                            <li>
                                <Link to="/Contact" style={{ color: '#fff', textDecoration: 'none' }}>
                                    <Typography variant="body3">
                                        Contact
                                    </Typography>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Vision" style={{ color: '#fff', textDecoration: 'none' }}>
                                    <Typography variant="body3">
                                        Vision
                                    </Typography>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Services" style={{ color: '#fff', textDecoration: 'none' }}>
                                    <Typography variant="body3">
                                        Services
                                    </Typography>
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;
