import Nav from './Nav';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom'
import Vision from './Vision'
import Home from './Home'
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import ServiceDetail from './ServiceDetail';

let theme = createTheme({

  palette: {
    primary: {
      main: '#00154A',
      light: 'rgba(0, 21, 74, 0.75)'
    },
    secondary: {
      main: 'rgba(240, 34, 26, 0.75)',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Lilita One',
      fontSize: '60px',
    },
    h2: {
      fontFamily: 'Lilita One',
      fontSize: '30px'
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '30px',
      fontWeight: 500, // Medium weight
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '18px'
    },
    navFont: {
      fontFamily: 'Lilita One',
      fontSize: '23px',
      fontWeight: 400, 
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      fontWeight: 400, 
    },
    body2: {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      fontWeight: 500, 
    },
    body3: {
      fontFamily: 'Montserrat',
      fontSize: '13px'
    },
    body4: {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      fontWeight: 700, 
    },
    attribution: {
      fontFamily: 'Montserrat',
      fontSize: '9px'
    }
  },
});
theme = responsiveFontSizes(theme);

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Nav />
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Vision" element={<Vision />} />
          <Route path="/services/:type" element={<ServiceDetail />} />
        </Routes>
        <Footer />
      </div>
    </ThemeProvider >
  );
}

export default App;
