import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCnPkJ1Nqpe8XG8JKFePqT2QD645fPE2FQ",
  authDomain: "bms-ship-3c6fc.firebaseapp.com",
  projectId: "bms-ship-3c6fc",
  storageBucket: "bms-ship-3c6fc.appspot.com",
  messagingSenderId: "367403176187",
  appId: "1:367403176187:web:0f67ce794f997f8a2f605e",
  measurementId: "G-VM1R1SQRNW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

