
import React, { useState } from 'react';
import { TextField, Grid, Button, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { ref, getDatabase, push as firebasePush } from 'firebase/database'
import AlertDialog from './AlertDialog';

function Contact() {
    const theme = useTheme();

    const formStyles = {
        padding: '2rem'
    }

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
    });

    const headerStyle = {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '3rem 12rem',
    };

    const contactStyle = {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: '3rem 12rem',
    }


    const [errors, setErrors] = useState({});
    const [isAlertOpen, setAlertOpen] = useState(false);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = {};

        // Check if required fields are empty
        if (!formData.firstName.trim()) {
            validationErrors.firstName = 'First name is required';
        }
        if (!formData.lastName.trim()) {
            validationErrors.lastName = 'Last name is required';
        }
        if (!formData.email.trim()) {
            validationErrors.email = 'Email is required';
        }
        if (!formData.message.trim()) {
            validationErrors.message = 'Message is required';
        }



        if (Object.keys(validationErrors).length === 0) {
            // Get the database reference
            const db = getDatabase();

            // Get a reference to the 'users' node in your database
            const usersRef = ref(db, 'users');

            // Add the formData to a new unique child under 'users'
            firebasePush(usersRef, formData)
                .then(() => {
                    // You can clear the form or do something to show a successful submission
                    // Reset the form state if needed
                    setAlertOpen(true);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        message: '',
                    });
                })
                .catch((error) => {
                    // Handle errors here, such as showing a message to the user
                    console.error("Error writing to Firebase Database", error);
                });
        } else {
            setErrors(validationErrors);
        }
    };



    return (
        <>
            <div style={headerStyle}>
            </div>

            <div style={contactStyle}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h2" style={{ marginBottom: '1rem' }}>Contact Us</Typography>
                </Grid>
            </div>
            {isAlertOpen && (
            <AlertDialog 
                isOpen={isAlertOpen}
                onClose={() => setAlertOpen(false)}
            />
        )}

            <form style={formStyles} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            required
                            value={formData.firstName}
                            onChange={handleInputChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            variant="outlined"
                            fullWidth
                            required
                            value={formData.lastName}
                            onChange={handleInputChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email Address"
                            name="email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            variant="outlined"
                            fullWidth
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Message"
                            name="message"
                            variant="outlined"
                            fullWidth
                            required
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleInputChange}
                            error={!!errors.message}
                            helperText={errors.message}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{
                                fontFamily: theme.typography.h2.fontFamily,
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>


        </>
    )
}

export default Contact;
