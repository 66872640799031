import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom';

function ServiceCards() {

    const serviceCardData = [
        {
            title: "Agency Services",
            imgPath: `${process.env.PUBLIC_URL}/images/5.jpg`,
            attribution: 'Chris Pagan on Unsplash',
            type: 'agency-services'
        },
        {
            title: "Inspections",
            imgPath: `${process.env.PUBLIC_URL}/images/6.jpg`,
            attribution: 'Ian Taylor on Unsplash',
            type: 'inspections'
        },
        {
            title: "Audits",
            imgPath: `${process.env.PUBLIC_URL}/images/1.jpg`,
            attribution: 'Kaharlytskyi on Unsplash',
            type: 'audits'
        },
        {
            title: "Surveys",
            imgPath: `${process.env.PUBLIC_URL}/images/7.jpg`,
            attribution: '',
            type: 'surveys'
        },
        {
            title: "Marine Consultancy",
            imgPath: `${process.env.PUBLIC_URL}/images/consultancy.jpg`,
            attribution: 'snowing on Freepik',
            type: 'marine-consultancy'
        },
        {
            title: "Operational Coordination & Support",
            imgPath: `${process.env.PUBLIC_URL}/images/2.webp`,
            attribution: 'Meissner on Unsplash',
            type: 'operational-coordination'
        },
        {
            title: "Incident & Injury Investigations",
            imgPath: `${process.env.PUBLIC_URL}/images/investigation.jpg`,
            attribution: 'prostooleh on Freepik',
            type: 'incident-and-injury-investigations'
        },
        {
            title: "Warehouse Facilities + Spare Supplies",
            imgPath: `${process.env.PUBLIC_URL}/images/facilities.JPG`,
            attribution: '',
            type: "warehouse-facilities"

        }
    ];

    return (
        <Grid container spacing={2} sx={{ margin: '1rem' }} >
            {serviceCardData.map((card) => (
                <Grid item xs={12} sm={6} md={3} key={card.type}>
                    <ServiceCard title={card.title} imgPath={card.imgPath} attribution={card.attribution} type={card.type} />
                </Grid>
            ))}
        </Grid>
    )
}

export default ServiceCards;

function ServiceCard({ title, imgPath, attribution, type }) {
    const theme = useTheme();

    return (
        <Card sx={{ maxWidth: 345, position: 'relative' }}>
            <div style={{ position: 'relative' }}>
                <CardMedia
                    sx={{ height: 140, backgroundColor: 'white' }}
                    image={imgPath}
                    loading="lazy"
                />
                {/* Smaller Attribution overlay */}
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.20)',
                    color: 'white',
                    padding: '1px 2px',
                    fontSize: theme.typography.attribution.fontSize,
                    fontFamily: theme.typography.attribution.fontFamily
                }}>
                    {attribution}
                </div>
            </div>
            <CardContent sx={{ backgroundColor: '#377e8e' }}>
                <Typography gutterBottom variant="body2" component="div" textAlign={'center'} color='white'>
                    {title}
                </Typography>
            </CardContent>
            <CardActions sx={{
                justifyContent: "center", backgroundColor: '#377e8e'
            }}>
                <Link to={`/services/${type}`} style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{
                            fontFamily: theme.typography.h2.fontFamily,
                        }}
                    >
                        More Info
                    </Button>
                </Link>
            </CardActions>
        </Card>
    )
}
